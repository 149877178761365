<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          {{ uploadProp.name }}
          <span class="text-muted">
            ({{ uploadProp.filename_original }} / {{ uploadProp.filesize }})
          </span>
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon @click="downloadFile" :loading="downloadLoading">
          <v-icon>mdi-download-outline</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-slide-x-transition>
        <v-list-item-action
          v-if="uploadProp.released_at === null &&            
              ((uploadProp.type === 'extern' &&
                $hasPermissions('customer permission')) ||
                (uploadProp.type === 'intern' &&
                  $hasPermissions('consultant permission')))
          "
        >
          <v-btn icon @click="deleteFile" :loading="deleteLoading">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-slide-x-transition>
    </v-list-item>
    <v-divider />
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    uploadProp: {
      type: Object,
      default: () => {}
    },
    uploadRequestState: {
      type: String,
      default: null
    }
  },
  data: () => ({
    // functional
    downloadLoading: false,
    deleteLoading: false
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      showInquiryItem: "inquiryItem/show"
    }),

    downloadFile() {
      this.downloadLoading = true;
      this.axios
        .get("/upload/" + this.uploadProp.id + "/download", {
          responseType: "blob"
        })
        .then(r => {
          const url = URL.createObjectURL(new Blob([r.data]));
          const fileName = this.uploadProp.filename_original;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    },
    deleteFile() {
      this.deleteLoading = true;
      this.axios
        .delete("/uploads/" + this.uploadProp.id)
        .then(() => {
          this.$snotify.success("Das Dokument wurde erfolgreich entfernt.");
          this.showInquiryItem({ id: this.$route.params.id });
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Entfernen des Dokuments ist ein Fehler aufgetreten."
          );
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    }
  }
};
</script>

<style scoped></style>
